export const noticeVO = function () {
  return {
    notice: {
      id: null,
      orgId: null, //单位ID
      title: '', //标题
      publisher: { //发布人
        id: null,
        name: ''
      },
      publishTime: '', //发布时间
      resume: '', //简述
      content: '', //内容
      receiveType: '', //接受类型(all/dept/user)
      receiveIds: [], //接受对象IDS
      receiveNames: '', //接受对象名称
      idUser: '', //创建人id
      ifAnswer: '', //是否需要回复{yes/no}
      checkId: null, //审批部门id
      checkName: '', //审批部门名称
      ifCheck: 'unCheck', //审批状态（agree/disagree/unCheck）
      receiveNum: 0, //下达总数
      readNum: 0, //已经阅读数
      replyNum: 0, //已经回复数
      state: 0
    },
    fileIds: [],
    imageIds: [],
    files: [],
    images: []
  }
}