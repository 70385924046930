<template>
  <a-form layout="horizontal">
    <a-form-item label="标题"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.notice.title}}
    </a-form-item>
    <a-form-item label="发布人"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.notice.publisher.name}}
    </a-form-item>
    <a-form-item label="发布时间"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.notice.publishTime}}
    </a-form-item>
    <a-form-item label="简述"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.notice.resume}}
    </a-form-item>
    <a-form-item label="内容"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">
      <div v-html="formItem.notice.content"></div>
    </a-form-item>
    <a-form-item label="图片"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">
      <qiniu-image-upload
        ref="qiniuImageRef" @getImageData="getImageData"
        :document-dir-id="21"
        :multiSelection="true"
        type="view"
        :items="formItem.images"
      ></qiniu-image-upload>
    </a-form-item>
    <a-form-item label="附件"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">
      <qiniu-file-upload
        ref="qiniuFileRef" @getFileData="getFileData"
        :document-dir-id="20"
        :multiSelection="true"
        type="view"
        :items="formItem.files"
      ></qiniu-file-upload>
    </a-form-item>
    <a-form-item label="接收类型"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">
      <a-tag v-if="formItem.notice.receiveType==='all'" color="">全部</a-tag>
      <a-tag v-if="formItem.notice.receiveType==='dept'" color="green">部门</a-tag>
      <a-tag v-if="formItem.notice.receiveType==='user'" color="red">用户</a-tag>
    </a-form-item>
    <a-form-item label="接收对象"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.notice.receiveNames}}
    </a-form-item>
    <a-form-item label="审批部门"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.notice.checkName}}
    </a-form-item>
    <a-form-item label="需要回复"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.notice.ifAnswer==='yes'?'是':'否'}}
    </a-form-item>
    <a-form-item label="审核状态"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">
      <a-tag v-if="formItem.notice.ifCheck==='unCheck'" color="">未审核</a-tag>
      <a-tag v-if="formItem.notice.ifCheck==='agree'" color="green">审核通过</a-tag>
      <a-tag v-if="formItem.notice.ifCheck==='disagree'" color="red">审核不通过</a-tag>
    </a-form-item>
  </a-form>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import { noticeVO } from './common/noticeVO'
  import QiniuImageUpload from '../../../components/QiniuUpload/QiniuImageUpload'
  import QiniuFileUpload from '../../../components/QiniuUpload/QiniuFileUpload'

  export default {
    name: 'noticeDetail',
    components: { QiniuImageUpload, QiniuFileUpload },
    data () {
      return {
        formItem: noticeVO(),
        labelCol: { span: 5 },
        wrapperCol: { span: 18 }
      }
    },
    created () {
    },
    methods: {
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.platBase.notice.view,
          params: {
            id: id
          },
          noTips: 'true',
          success: (data) => {
            Object.assign(this.formItem, data.body)
          }
        })
      }
    }
  }
</script>