<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-form-item label="标题"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input v-decorator="['title', {rules: [{ required: true, message: '请输入标题'}]}]"
                 placeholder="请输入标题" disabled></a-input>
      </a-form-item>
      <a-form-item label="发布时间"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-date-picker format="YYYY-MM-DD" style="width: 100%"
                       v-decorator="['publishTime', {rules: [{ required: true, message: '请选择发布时间'}]}]"
                       placeholder="请选择发布时间" disabled></a-date-picker>
      </a-form-item>
      <a-form-item label="简述"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input type="textarea" rows="4"
                 v-decorator="['resume']"
                 placeholder="请输入简述" disabled/>
      </a-form-item>
      <a-form-item label="内容"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <div v-html="formItem.notice.content"></div>
      </a-form-item>
      <a-form-item label="图片"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef" @getImageData="getImageData"
          :document-dir-id="21"
          :multiSelection="true"
          type="view"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
      <a-form-item label="附件"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <qiniu-file-upload
          ref="qiniuFileRef" @getFileData="getFileData"
          :document-dir-id="20"
          :multiSelection="true"
          type="view"
          :items="formItem.files"
        ></qiniu-file-upload>
      </a-form-item>
      <a-form-item label="接收类型"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select placeholder="请选择接收类型" disabled @change="receiveTypeChange"
                  v-decorator="['receiveType', {rules: [{ required: true, message: '请选择接收类型'}]}]">
          <a-select-option
            v-for="item in ReceiveTypeData"
            :key="item.label"
            :value="item.value"
            :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="接收对象" v-if="formItem.notice.receiveType!=='all'"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select placeholder="请选择接收对象" disabled mode="multiple"
                  v-decorator="['receiveIds',]">
          <a-select-option
            v-for="item in receiveData"
            :key="item.id"
            :value="item.id"
            :label="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="审批部门"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select placeholder="请选择审批部门" disabled
                  v-decorator="['checkId', {rules: [{ required: true, message: '请选择审批部门'}]}]">
          <a-select-option
            v-for="item in selfDepts"
            :key="item.id"
            :value="item.id"
            :label="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="需要回复"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-radio-group :options="IfAnswer" disabled
                       v-decorator="['ifAnswer',{initialValue: 'no',rules:[{type:'string',required:true,message:'需要回复为必填项'}],trigger:'change'}]">
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import { noticeVO } from './common/noticeVO'
  import { ifAnswer, receiveTypeData } from './common/common'
  import moment from 'moment'
  import QiniuImageUpload from '../../../components/QiniuUpload/QiniuImageUpload'
  import QiniuFileUpload from '../../../components/QiniuUpload/QiniuFileUpload'

  export default {
    name: 'noticeForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    components: { QiniuImageUpload, QiniuFileUpload },
    data () {
      return {
        spinShow: false,
        ifSub: false,
        form: this.$form.createForm(this),
        formItem: noticeVO(),
        IfAnswer: ifAnswer(),
        ReceiveTypeData: receiveTypeData(),
        labelCol: { span: 4 },
        wrapperCol: { span: 19 },
        receiveData: [], //接收对象
        usersData: [] //当前单位下所有用户
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      },
      currentOrg () {
        return this.$store.getters.currentOrg
      },
      selfUnits () {
        return this.$store.getters.selfUnits
      },
      selfDepts () {
        return this.$store.getters.selfDepts
      }
    },
    mounted () {
    },
    created () {
      this.formItem.notice.orgId = this.currentOrg.id
      this.formItem.notice.publisher.id = this.currentUser.id
      this.loadUsersData()
    },
    methods: {
      //接收类型改变
      receiveTypeChange (e) {
        this.formItem.notice.receiveType = e
        this.formItem.notice.receiveIds = []
        this.form.setFieldsValue({
          receiveIds: []
        })
        if (this.formItem.notice.receiveType === 'all') {
          this.receiveData = []
        } else if (this.formItem.notice.receiveType === 'dept') {
          this.receiveData = this.selfDepts
        } else {
          this.receiveData = this.usersData
        }
      },
      loadData (id) {
        //清空表单数据
        this.formItem = noticeVO()
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.platBase.notice.view,
          params: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.setFields(data.body)
            })
            //赋值接收对象
            this.receiveTypeChange(data.body.notice.receiveType)
            this.spinShow = false
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          title: this.formItem.notice.title,
          publishTime: moment(this.formItem.notice.publishTime),
          resume: this.formItem.notice.resume,
          content: this.formItem.notice.content,
          receiveType: this.formItem.notice.receiveType,
          receiveIds: this.formItem.notice.receiveIds,
          receiveNames: this.formItem.notice.receiveNames,
          ifAnswer: this.formItem.notice.ifAnswer,
          checkId: this.formItem.notice.checkId
        })
      },
      //审批通知
      approvalNotice (type) {
        this.$http(this, {
          url: SERVICE_URLS.platBase.notice.approval,
          params: {
            id: this.formItem.notice.id,
            type: type
          },
          success: (data) => {
            //审批成功触发回调
            this.$emit('approvalSuccess', data.body)
          },
          error: () => {
            this.$emit('approvalError')
          }
        })
      },
      loadUsersData () {
        this.$http(this, {
          url: SERVICE_URLS.user.userOrgJob.orgUser,
          noTips: true,
          success: (data) => {
            this.usersData = data.body
          }
        })
      }
    }
  }
</script>